import React from 'react';

function Light() {
  return (
    <span className="w-4 h-4 mt-1 mr-2 inline-flex items-center justify-center bg-green-100 text-green-800 dark:text-green-500 drark:bg-transparent rounded-full flex-shrink-0">
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        className="w-3 h-3"
        viewBox="0 0 24 24"
      >
        <path d="M20 6L9 17l-5-5" />
      </svg>
    </span>
  );
}

export default Light;
