import React from 'react';
import Image from 'next/image';
import stringToSlug from '../../lib/utils/stringToSlug';

export default function ContentContainer({
  title,
  children,
  imgSrc,
  whiteTheme,
  imageLeft,
  useH1,
  topic = '',
  use5050WidthInLargeScreen = false,
  imageQuality = 75,
  imageIsPriority = false,
  imageHeight = 500,
  imageWidth = 500,
}) {
  return (
    <section
      className={`${
        whiteTheme
          ? 'bg-gradient-to-b from-gray-50 to-white text-gray-700'
          : 'bg-gradient-to-b from-gray-900 to-black text-white-600'
      }`}
      id={stringToSlug(`${title}-${topic}`)}
    >
      <div className="container px-2 md:px-5 py-12 mx-auto flex flex-wrap">
        {/* Image  */}
        <div
          className={`w-10/12 ${
            use5050WidthInLargeScreen ? 'lg:w-1/2' : 'lg:w-2/5'
          } m-auto pb-2 px-0 lg:px-8 ${imageLeft ? 'lg:order-last' : ''}`}
        >
          <Image
            src={imgSrc}
            className="default-image"
            alt={`${title} image`}
            height={imageHeight}
            width={imageWidth}
            quality={imageQuality}
            priority={imageIsPriority}
            layout="responsive"
          />
        </div>
        {/* End Image  */}

        {/* Text explanation  */}
        <div
          className={`my-auto flex flex-col flex-wrap lg:py-3  ${
            use5050WidthInLargeScreen ? 'lg:w-1/2' : 'lg:w-3/5'
          }  lg:pl-12 lg:text-left text-center `}
        >
          {topic && (
            <p className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
              {topic}
            </p>
          )}
          {useH1 ? (
            <h1 className={`${whiteTheme ? '' : 'text-white'}`}>{title}</h1>
          ) : (
            <h2 className="text-indigo-600 text-4xl font-bold">{title}</h2>
          )}
          {children}
        </div>
        {/* Text explanation  */}
      </div>
    </section>
  );
}
ContentContainer;
