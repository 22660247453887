import React from 'react';
import {
  AcademicCapIcon,
  BookOpenIcon,
  OfficeBuildingIcon,
  ChartBarIcon,
  ChatIcon,
  UserGroupIcon,
  ArrowRightIcon,
} from '@heroicons/react/outline';
import CheckMark from '../../layout/checkmark/Light';

function ContentCard(props) {
  return (
    <div className="p-4 flex flex-col text-center items-center">
      <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-1 flex-shrink-0">
        <props.icon className="w-10 h-10" />
      </div>
      <h3 className="text-lg font-bold my-4">{props.title}</h3>
      <div className="flex-grow">
        <props.contentComponent />

        <ul>
          {props.contentList.map((point, index) => (
            <li key={index} className="flex items-start">
              <div className="pt-2">
                <CheckMark />
              </div>
              <p
                className="text-left text-gray-600 mb-2"
                dangerouslySetInnerHTML={{ __html: point }}
              />
            </li>
          ))}
        </ul>
        {props.learnMoreLink && (
          <Link href={props.learnMoreLink}>
            <a className="mt-3 text-indigo-500 inline-flex items-center">
              Learn More
              <ArrowRightIcon className="w-4 h-4 ml-2" />
            </a>
          </Link>
        )}
      </div>
    </div>
  );
}

export default function QuickFeatures() {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-white text-gray-700">
      <div className="container mx-auto py-6 px-0 md:px-6 dark:bg-gray-800">
        <div className="text-center">
          <p className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
            Quick Feature List
          </p>
          <h2 className="my-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            The Operating System for Schools
          </h2>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
            How modern schools are run
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 bg-indigo-500 inline-flex" />
          </div>
        </div>

        <div className="flex flex-wrap my-8 dark:text-white">
          <div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 p-0 md:p-8">
            <ContentCard
              icon={OfficeBuildingIcon}
              title="100% Cloud-based School Management System"
              contentList={[
                "A school management system designed to put the school management in the know, parents at the centre of school communications, keep students engaged in their learning and make teachers' work easier.",
              ]}
              contentComponent={() => null}
            />
          </div>
          <div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r p-0 md:p-8">
            <ContentCard
              icon={ChatIcon}
              title="Communicate with your Institution"
              contentList={[
                'The staff, parents & students can receive updates from the school office via SMS or email immediately.',
              ]}
              contentComponent={() => null}
            />
          </div>
          <div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0 p-0 md:p-8">
            <ContentCard
              icon={ChartBarIcon}
              title="Exams"
              contentList={[
                'Student report cards are no longer limited to A4 size pages - report cards can be viewed as soft copy pages via phone or web!',
                'Send results via email or text message to parents and students.',
              ]}
              contentComponent={() => null}
            />
          </div>
          <div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0 p-0 md:p-8">
            <ContentCard
              icon={AcademicCapIcon}
              title="E-Learning, Assignments & Holiday Homework"
              contentList={[
                'Students can now engage in a combination of in-person learning and virtual learning.',
                'Can receive and do assignments and homework while at home, during weekends and even holidays.',
              ]}
              contentComponent={() => null}
            />
          </div>
          <div className="w-full border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0 p-0 md:p-8">
            <ContentCard
              icon={BookOpenIcon}
              title="Library Management System"
              contentList={[
                'Have an online library management system that will be used to issue books, receive books, analyse the most popular books, etc.',
                'Book information records system.',
                'Track & prevent book loss.',
              ]}
              contentComponent={() => null}
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-0 md:p-8">
            <ContentCard
              icon={UserGroupIcon}
              title="Online web portals for staff, students and parents to log in"
              contentList={[
                "View students' performance, books borrowed, disciplinary issues, school events.",
                'Online noticeboard.',
                'Clarity about all parts of your school - access everything in one place.',
              ]}
              contentComponent={() => null}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
