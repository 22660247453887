import React from 'react';
import Typewriter from 'typewriter-effect';
import Link from 'next/link';
import ContentContainer from '../../layout/content/ContentContainer';
import QuickFeatures from '../Features/QuickFeatures';

export function Home(props) {
  return (
    <>
      {/* <!--/ header block --> */}

      <ContentContainer
        title="The Operating System for Schools"
        imgSrc="/images/screenshots/school/ExamResults.png"
        imageLeft
        useH1
        imageIsPriority
        imageQuality={25}
        imageHeight={900}
        imageWidth={1440}
        use5050WidthInLargeScreen
      >
        <div className="flex flex-col mb-10 lg:items-start items-center">
          {/* <div className="w-12 h-12 inline-flex items-center  justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                            <ChatIcon className="w-6 h-6 " />
                        </div> */}
          <h5 className="text-white  mb-1">
            Shulesuite is designed to put the school management in the know, parents at the center
            of school communications, students engaged in their learning and ensuring teachers' work
            is easier.
          </h5>

          <div className="flex flex-col">
            <p className="text-gray-200 mb-5 order-last md:order-first">
              <b className="font-bold">
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .pauseFor(150)
                      .changeDeleteSpeed(50)
                      .typeString('How schools')
                      .pauseFor(150)
                      .deleteChars(7)
                      .typeString('modern schools ')
                      .pauseFor(100)
                      .typeString('get actionable insights from their data.')
                      .pauseFor(250)
                      .changeDeleteSpeed(15)
                      .deleteChars(40)
                      .typeString('communicate with students.')
                      .pauseFor(250)
                      .deleteChars(9)
                      .typeString('parents.')
                      .pauseFor(250)
                      .deleteChars(25)
                      .typeString('issue assignments / homework.')
                      .pauseFor(250)
                      .deleteChars(29)
                      .typeString('make data driven decisions.')
                      .pauseFor(250)
                      .deleteChars(27)
                      .typeString('help students grow fully.')
                      .pauseFor(150)
                      .start();
                  }}
                />
              </b>
            </p>

            {/* <div></div> */}
          </div>

          <p className="mt-3">
            <Link href="/get-started">
              <a className="analytics-contact-us-link primary-button ">
                <span className="font-bold  flex items-baseline">
                  Get started today
                  <svg
                    className="w-4 h-4 ml-2 pt-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </span>
              </a>
            </Link>
          </p>
        </div>

        {/* Text explanation  */}
      </ContentContainer>

      <div className="bg-gradient-to-b from-gray-50 to-white text-gray-700 text-center py-2">
        {/* <div className="flex my-4 justify-center">
                    <div className="w-16 h-1 bg-indigo-500 inline-flex"></div>
                </div> */}
        <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          Not Just Another School Management System
        </h2>
        <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
          School management, E-learning, Powerful Data Analytics & Communication Tools For Modern
          Schools
        </p>
        <div className="flex my-4 justify-center">
          <div className="w-16 h-1 bg-indigo-500 inline-flex" />
        </div>
      </div>

      <ContentContainer
        title="90% of people read an SMS within the first 1-5 minutes"
        imgSrc="/images/Communication.svg"
        whiteTheme
        topic="SMS & Email"
      >
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="flex-grow">
            <h3 className="text-indigo-600 text-xl font-bold mb-3">
              AGM Coming up? Harambee? Fee payment reminders?
            </h3>
            <p className="">
              Have an AGM/Harambee coming up? Want to remind parents to complete the fee payment?
              Important announcement? No longer rely on students to deliver this information as it
              can get distorted or not delivered at all.
            </p>
            <p className="">
              We understand that one of the major struggles in most schools is ensuring efficient
              communication. Some parents{' '}
              <b>
                have missed AGM meetings, failed to pay fees or even fail show up during on
                opening/closing day{' '}
              </b>
              due to a pro`blem in communication. With our system, you can communicate via{' '}
              <b>both email and SMS</b> to all parents, students or staff, at the same time, with
              the click of a button.
            </p>
          </div>
        </div>
      </ContentContainer>

      <ContentContainer
        title="Exam report cards are no longer limited to A4 size pages!"
        imgSrc="/images/screenshots/school/StudentExamResults.png"
        // whiteTheme
        imageLeft
        imageQuality={25}
        imageHeight={900}
        imageWidth={1440}
        topic="Exams & Exam Results/Reports"
      >
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="flex-grow">
            <h3 className="text-indigo-600 text-xl font-bold mb-3">
              Interactive report cards with charts and graphs
            </h3>
            <p className="">
              No longer limit your exam report card to A4 size pages that students can hide or
              forge.
            </p>
            <p className="">
              ShuleSuite comes with <u>interactive reports</u> that involve{' '}
              <u>detailed charts and graphs</u> per subject etc, comparison with past exams, ranking
              viewed on the school portal.
            </p>
            <h3 className="text-indigo-600 text-xl font-bold mb-3">
              Send exam results via SMS & Email
            </h3>
            <p className="">
              The <b>exam results are also delivered via SMS</b> and Email guaranteeing the parents
              receive them.
            </p>
          </div>
        </div>
      </ContentContainer>

      <ContentContainer
        title="Everything about a student in the palm of your hand"
        imgSrc="/images/illustrations/twoMenChart.png"
        id="school-management"
        imageHeight={350}
        whiteTheme
        topic="Admin & Staff Portal"
      >
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="flex-grow">
            <p className="">
              Imagine a parent/student walking in to your office and <b>in an instant</b>, you have
              all the about the student in the palm of your hand. <br />
              You know{' '}
              <b>
                how the student has historically performed in exams, which books he/she is
                borrowing, disciplinary issues, the parent’s contacts,
              </b>{' '}
              etc.
            </p>

            <p className="">
              ShuleSuite gives you this kind of clarity about all parts of your school; the library,
              students, staff, exams, school events in a way never seen before.
            </p>
          </div>
        </div>
      </ContentContainer>

      <ContentContainer
        title=" Parents feel detached and often don’t get the information they need in time"
        imgSrc="/images/screenshots/school/StudentDashboard.png"
        id="school-management"
        imageLeft
        topic="Parents & Students Portal"
        imageQuality={25}
        imageHeight={900}
        imageWidth={1440}

        // whiteTheme
      >
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="flex-grow">
            <p className="">
              Parents often don’t get the information they need in time and feel detached from their
              children’s school. <br />
              They want to know what their children are up to, how they are performing, what dates
              school events are scheduled for, etc without having to come to the office or keep on
              calling the school.
            </p>

            <h3 className="text-indigo-600 text-xl font-bold mb-3">How things work today</h3>

            <p className="">
              Today, parents <u>do everything from their phones</u> from banking, government
              services to insurance and paying tax via their phone. ShuleSuite enables them to do
              the same with schooling for your school.
            </p>

            <p className="">
              They see everything from the school portal via their phone; can see when important
              events will be, can see the exam reports & historical performance of the student, get
              notifications, e.t.c
            </p>

            <p className="">
              This makes them happier and makes them proud; viewing their child’s school as{' '}
              <u>modern and innovative</u>.
            </p>
          </div>
        </div>
      </ContentContainer>

      <ContentContainer
        title="E-Learning, assignments & holiday homework done and marked online"
        imgSrc="/images/Analytics.svg"
        whiteTheme
        topic="E-Learning"
      >
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="flex-grow">
            <p className="">
              ShuleSuite give schools tools to manage online assignments / homework.
            </p>
            <h3 className="text-indigo-600 text-xl font-bold mb-3">The new normal</h3>
            <p className="">
              Remote learning and collaboration is the new norm for modern schools especially after
              the COVID-19 pandemic. As <b>Satya Nadella, the Microsoft CEO </b> put it, “We’ve seen
              two years’ worth of digital transformation in two months. From remote teamwork and
              learning to sales and customer service, to critical cloud infrastructure and security
              - we are working alongside customers every day to help them adapt and stay open for
              business in a world of remote everything.” (Jared Spataro and Microsoft 365, 2020)
            </p>
          </div>
        </div>
      </ContentContainer>

      <ContentContainer
        title="A university-like library experience"
        imgSrc="/images/illustrations/textPage.svg"
        // whiteTheme
        // topic="E-Learning"
        imageLeft
        topic="Library Management System"
      >
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="flex-grow">
            <p className="">
              How many ‘KLB Mathematics Book 4’ are in the library? Which books are often borrowed?
              How many books did student X borrow in the last month?
            </p>
            <p className="">
              ShuleSuite helps you answer these questions and more with the integrated Library
              Management System. It has a book information records system, issue and return books is
              done via the system, e.t.c
            </p>
          </div>
        </div>
      </ContentContainer>

      <ContentContainer
        title="Stand out and stay competitive"
        imgSrc="/images/illustrations/manWomanAndManPointingAtChart.svg"
        whiteTheme
        topic="100% cloud-based school management system"
      >
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="flex-grow">
            <p className="">
              The 100% cloud-based school management system, available anytime from anywhere will
              prove to make you and your school proud.
            </p>
            <h3 className="text-indigo-600 text-xl font-bold mb-3">
              Modern tech savvy institution
            </h3>
            <p className="">
              It comes with a <b>customised website login</b> with your school’s branding (logo,
              motto, etc.) and is a one point solution for total school management.
            </p>
            <p className="">
              This reflects your school unique brand and helps build an image of{' '}
              <b>modern savvy institution</b> that invests in its students.
            </p>
          </div>
        </div>
      </ContentContainer>

      <QuickFeatures />
    </>
  );
}

export default Home;
